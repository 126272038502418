import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { AnimatedText } from 'components/animated-text';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { motion, useInView } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { string } from 'prop-types';

import { useTrustedSectionData } from './hooks';

const SContainer = styled(Container)`
    text-align: center;
    content-visibility: auto;
    contain-intrinsic-size: 250px;
`;

const SHeader = styled(HeaderSecond)`
    font-weight: 300;
    max-width: 58rem;
    margin: 0 auto 5rem;
`;

const SProjectsWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 5.75rem;
    justify-content: center;
    align-items: center;
    ${CONSTANTS.MEDIA.max1024`
        display: grid;
        justify-items: center;
        gap: 4.75rem;
        grid-template-columns: repeat(3, 1fr);
    `}
    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${CONSTANTS.MEDIA.max500`
        grid-template-columns: 1fr;
    `}
`;

const text = [
    {
        text: 'We take responsibility for delivering expected outcomes on time and to a top-notch standard. The team that can deploy products in the most efficient way.',
        underlineText: 'on time and to a top-notch standard most efficient way',
        underlineWordIndexes: [7, 8, 9, 10, 11, 12, 13, 22, 23],
    },
];

const containerVariants = {
    visible: {
        transition: {
            staggerChildren: 0.025,
        },
    },
};

const brandVariants = {
    hidden: {
        y: '200%',
        transition: { duration: 0.5 },
    },
    visible: {
        y: 0,
        transition: { duration: 0.5 },
    },
};

export const TrustedSection = ({ className }) => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const { formatMessage } = useIntl();
    const logoData = useTrustedSectionData();

    return (
        <div ref={ref}>
            <SContainer className={className} id="trusted">
                <motion.div
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    variants={containerVariants}
                >
                    <SHeader className="header">
                        {text.map((item, index) => {
                            return <AnimatedText {...item} key={index} />;
                        })}
                    </SHeader>
                    <SProjectsWrapper>
                        {logoData.map(({ image, altKey }, index) => (
                            <div
                                key={index}
                                style={{
                                    overflow: 'hidden',
                                    display: 'inline-block',
                                }}
                            >
                                <motion.div
                                    style={{ display: 'inline-block' }}
                                    variants={brandVariants}
                                >
                                    <GatsbyImage
                                        image={image}
                                        alt={formatMessage({
                                            id: altKey,
                                        })}
                                    />
                                </motion.div>
                            </div>
                        ))}
                    </SProjectsWrapper>
                </motion.div>
            </SContainer>
        </div>
    );
};

TrustedSection.propTypes = {
    className: string,
};

TrustedSection.defaultProps = {
    className: '',
};
