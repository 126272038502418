import { graphql, useStaticQuery } from 'gatsby';

export const useTrustedSectionData = () => {
    const images = useStaticQuery(graphql`
        {
            cbre: file(relativePath: { eq: "projects/logos/cbre.webp" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 116
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            euroZet: file(
                relativePath: { eq: "projects/logos/euro-zet.webp" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 144
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            nomis: file(relativePath: { eq: "projects/logos/nomis.webp" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 140
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            tuneGo: file(relativePath: { eq: "projects/logos/tune-go.webp" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 155
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            urbanOne: file(
                relativePath: { eq: "projects/logos/urban-one.webp" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 171
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return [
        {
            image: images.tuneGo.childImageSharp.gatsbyImageData,
            altKey: 'trusted-section.alts.tuneGo',
        },
        {
            image: images.euroZet.childImageSharp.gatsbyImageData,
            altKey: 'trusted-section.alts.eurozet',
        },
        {
            image: images.cbre.childImageSharp.gatsbyImageData,
            altKey: 'trusted-section.alts.cbre',
        },
        {
            image: images.urbanOne.childImageSharp.gatsbyImageData,
            altKey: 'trusted-section.alts.urbanOne',
        },
        {
            image: images.nomis.childImageSharp.gatsbyImageData,
            altKey: 'trusted-section.alts.nomis',
        },
    ];
};
